import { MINUTE } from "shared/lib/constants/timeConstants";

export const isTest = process.env.NODE_ENV === "test";

const DEFAULT_GROUPING_TOOL_MAX_SELECTED_STUDENTS = 12;

/**
 * The max number of students able to be selected for a lesson or formative assessment.
 * Defaults to 12, anything over 12 is experimental.
 */
export const GROUPING_TOOL_MAX_SELECTED_STUDENTS = (() => {
  const maxSelectedFromEnv =
    process.env.REACT_APP_GROUPING_TOOL_MAX_SELECTED_STUDENTS;
  if (maxSelectedFromEnv) {
    const max = parseInt(maxSelectedFromEnv, 10);
    if (Number.isFinite(max)) {
      return max;
    }
  }
  return DEFAULT_GROUPING_TOOL_MAX_SELECTED_STUDENTS;
})();

const DEFAULT_SESSION_REFRESH_INTERVAL = 30 * MINUTE;

/**
 * The session refresh interval.
 * Controlled by REACT_APP_SESSION_REFRESH_INTERVAL_MINUTES, which should be the number of minutes.
 */
export const SESSION_REFRESH_INTERVAL = (() => {
  const intervalFromEnv =
    process.env.REACT_APP_SESSION_REFRESH_INTERVAL_MINUTES;
  if (intervalFromEnv) {
    const interval = parseInt(intervalFromEnv, 10);
    if (Number.isFinite(interval)) {
      return interval * MINUTE;
    }
  }
  return DEFAULT_SESSION_REFRESH_INTERVAL;
})();
